import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import Loadable from 'react-loadable'

import Loader from 'components/LayoutComponents/Loader'
import IndexLayout from 'layouts'
import NotFoundPage from 'pages/404'
import CacheBuster from './cacheBuster'

const loadable = (loader) =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  //  Preloaded Template Routes
  // System Pages
  {
    path: '/vcAssessment',
    component: loadable(() => import('pages/vcAssessment')),
    exact: true,
  },
  {
    path: '/user/login',
    component: loadable(() => import('pages/user/login')),
    exact: true,
  },
  {
    path: '/user/forgot',
    component: loadable(() => import('pages/user/forgot')),
    exact: true,
  },

  // Dashboards
  /*  {
    path: '/dashboard/alpha',
    component: loadable(() => import('pages/team/view')),
    exact: true,
  }, */

  // Custom Application Routes
  /*  {
    path: '/coach/profile/',
    component: loadable(() => import('pages/team/view')),
    exact: true,
  },
  {
    path: '/analytics',
    component: loadable(() => import('pages/customizedReport')),
    exact: true,
  }, */
  {
    path: '/profile/:slug',
    component: loadable(() => import('pages/profile')),
    exact: true,
  },

  //  Event Routes
  /* {
    path: '/event/',
    component: loadable(() => import('pages/event/manage')),
    exact: true,
  },
  {
    path: '/event/add',
    component: loadable(() => import('pages/event/add')),
    exact: true,
  },
 */
  //  Device Routes
  /* {
    path: '/device/',
    component: loadable(() => import('pages/device/manage')),
    exact: true,
  },
  {
    path: '/device/add',
    component: loadable(() => import('pages/device/add')),
    exact: true,
  },
  {
    path: '/device/assign/add',
    component: loadable(() => import('pages/assignedDevices/add')),
    exact: true,
  }, */

  //  Members Routes
  /*  {
    path: '/members/add',
    component: loadable(() => import('pages/user/add')),
    exact: true,
  },
  {
    path: '/members',
    component: loadable(() => import('pages/user/manage')),
    exact: true,
  }, */

  //  Player Routes
  /* {
    path: '/player',
    component: loadable(() => import('pages/player/manage')),
    exact: true,
  },
  {
    path: '/player/add',
    component: loadable(() => import('pages/player/add')),
    exact: true,
  }, */

  //  Team Routes
  /* {
    path: '/team',
    component: loadable(() => import('pages/team/manage')),
    exact: true,
  },
  {
    path: '/team/add',
    component: loadable(() => import('pages/team/add')),
    exact: true,
  }, */
  /*
  //  Notification Routes
  {
    path: '/notification',
    component: loadable(() => import('pages/notification/manage')),
    exact: true,
  },
 */
  //  Device Inventory Routes
  /*  {
    path: '/inventory',
    component: loadable(() => import('pages/deviceInventory/manage')),
    exact: true,
  },
  {
    path: '/device/:slug',
    component: loadable(() => import('pages/deviceInventory/view')),
    exact: true,
  },
  {
    path: '/inventory/add',
    component: loadable(() => import('pages/deviceInventory/add')),
    exact: true,
  }, */

  //  Company Routes
  /* {
    path: '/company/add',
    component: loadable(() => import('pages/company/add')),
  },
  {
    path: '/company',
    component: loadable(() => import('pages/company/manage')),
  }, */

  //  Device Routes

  /* {
    path: '/device/assign/add',
    component: loadable(() => import('pages/assignedDevices/add')),
    exact: true,
  },
  {
    path: '/device/assign/manage',
    component: loadable(() => import('pages/assignedDevices/manage')),
    exact: true,
  }, */

  // Earning Logs Routes
  /*  {
    path: '/income',
    component: loadable(() => import('pages/revenue/manage')),
    exact: true,
  }, */

  //  User Routes

  /* {
    path: '/coaches',
    component: loadable(() => import('pages/coaches/manage')),
    exact: true,
  },
  {
    path: '/coaches/add',
    component: loadable(() => import('pages/coaches/add')),
    exact: true,
  },
  {
    path: '/consumers',
    component: loadable(() => import('pages/consumers/manage')),
    exact: true,
  },
  {
    path: '/superadmin',
    component: loadable(() => import('pages/superAdmin/manage')),
    exact: true,
  },
  {
    path: '/superadmin/add',
    component: loadable(() => import('pages/superAdmin/add')),
    exact: true,
  }, */
]

@connect(({ user }) => ({ user }))
class Router extends React.Component {
  render() {
    const { history } = this.props
    const { user } = this.props
    const { role, details } = user
    const { userID } = details

    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null
          if (!loading && !isLatestVersion) {
            // You can decide how and when you want to force reload
            refreshCacheAndReload()
          }

          return (
            <ConnectedRouter history={history}>
              <IndexLayout>
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={() =>
                      role === 'superAdmin' ? (
                        <Redirect to={`/profile/${userID}`} />
                      ) : (
                        <Redirect to={`/profile/${userID}`} />
                      )
                    }
                  />
                  <Route
                    exact
                    path="/vcAssessment"
                    component={loadable(() => import('pages/vcAssessment'))}
                  />
                  {routes.map((route) => (
                    <Route
                      path={route.path}
                      component={route.component}
                      key={route.path}
                      exact={route.exact}
                    />
                  ))}
                  <Route component={NotFoundPage} />
                </Switch>
              </IndexLayout>
            </ConnectedRouter>
          )
        }}
      </CacheBuster>
    )
  }
}

export default Router
