import React from 'react'
import { connect } from 'react-redux'
import { Scrollbars } from 'react-custom-scrollbars'
import { Calendar, Badge } from 'antd'
import moment from 'moment'
import styles from './style.module.scss'

let calendarDATA = {}
let sleepRecorded = {}
function formatCSIData(data, sleepSessions) {
  // const calendarDATA
  calendarDATA = {}
  sleepRecorded = {}
  data.forEach(element => {
    let colorType = 'error'
    if (element.csi_final >= 80) {
      colorType = 'success'
    } else if (element.csi_final >= 33) {
      colorType = 'warning'
    }
    calendarDATA[element.date] = [
      {
        type: colorType,
        content: Math.round(element.csi_final),
      },
    ]
  })
  if (sleepSessions.length > 0) {
    sleepSessions.forEach(element => {
      sleepRecorded[element.date_of_sleep] = [
        {
          type: 'processing',
          content: '',
        },
      ]
    })
  }
}
function getListData(value) {
  const itemName = moment(value).format('YYYY-MM-DD')
  let listData
  if (calendarDATA[itemName] !== undefined) {
    listData = calendarDATA[itemName]
  }
  return listData || []
}

function getListDataOfSleepSessions(value) {
  const itemName = moment(value).format('YYYY-MM-DD')
  let listData
  if (sleepRecorded[itemName] !== undefined) {
    listData = sleepRecorded[itemName]
  }
  return listData || []
}

function dateCellRender(value) {
  const listData = getListData(value)
  const sleepSessionsListData = getListDataOfSleepSessions(value)
  return (
    <ul className="events">
      {/* {listData.map(item => {
        let background = "#52c41a"
        if(item.type === "error"){
          background = "#f5222d"
        }else if(item.type === "warning"){
          background = "#faad14"
        }
        return <li key={item.content} style={{ background }}> <Badge status={item.type} text={item.content} /> </li>

      })} */}
      {listData.map(item => (
        <li key={item.content} title={`CSI ${item.content}%`} style={{ display: 'inline-block' }}>
          <Badge status={item.type} text="" />
        </li>
      ))}
      {sleepSessionsListData.map(item => (
        <li key={item.content} style={{ display: 'inline-block' }}>
          <Badge status={item.type} text="" />
        </li>
      ))}
    </ul>
  )
}
@connect(({ settings, profile }) => ({ settings, profile }))
class Settings extends React.Component {
  state = {
    value: moment(),
  }

  componentDidUpdate = prevProps => {
    const { value } = this.state
    const { profile } = this.props
    const { profile: prevProfile } = prevProps
    const { loading, nightByNightLoading } = profile
    const { loading: prevLoading, nightByNightLoading: prevNightByNightLoading } = prevProfile
    let { calendarDate } = profile
    calendarDate = moment(calendarDate)
    if (nightByNightLoading !== prevNightByNightLoading || prevLoading !== loading) {
      if (calendarDate.format('YYYY-MM-DD') !== value.format('YYYY-MM-DD')) {
        this.setState({
          value: calendarDate,
        })
      }
    }
  }

  onSelect = value => {
    this.setState({
      value,
    })

    const { dispatch } = this.props
    const selectedDate = moment(value).format('YYYY-MM-DD')
    this.getSleepStagesByDate(selectedDate)
    dispatch({
      type: 'settings/SET_STATE',
      payload: {
        isSettingsOpen: false,
      },
    })
  }

  onPanelChange = value => {
    this.setState({ value })
  }

  changeSetting = (setting, value) => {
    const { dispatch } = this.props
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting,
        value,
      },
    })
  }

  getSleepStagesByDate = date => {
    const { dispatch, profile } = this.props
    const { memberDetail } = profile
    dispatch({
      type: 'profile/GET_MEMBER_SLEEP_STAGES_HISTORY',
      payload: {
        userUID: memberDetail.user_id,
        date,
      },
    })
  }

  closeSettings = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isSettingsOpen',
        value: false,
      },
    })
  }

  render() {
    const { profile } = this.props
    const { memberCSIHistory, memberSleepSessions } = profile
    formatCSIData(memberCSIHistory, memberSleepSessions)
    const {
      settings: { isSettingsOpen },
    } = this.props
    const { value } = this.state

    return (
      <div
        className={isSettingsOpen ? `${styles.settings} ${styles.settingsOpened}` : styles.settings}
      >
        <Scrollbars style={{ height: '100vh' }}>
          <div className={styles.container}>
            <div className={styles.title}>
              Calendar
              <button
                className={`${styles.close} fa fa-times`}
                onClick={this.closeSettings}
                type="button"
              />
            </div>
          </div>
          <div className="col-lg-12">
            <b>CSI: </b> &nbsp;
            <Badge status="success" text="Good" /> &nbsp;
            <Badge status="warning" text="Average" /> &nbsp;
            <Badge status="error" text="Bad" />
            <br />
            <b>Sleep Session: </b> &nbsp;
            <Badge status="processing" text="Recorded" /> &nbsp;
          </div>
          <Calendar
            value={value}
            onPanelChange={this.onPanelChange}
            fullscreen={false}
            dateCellRender={dateCellRender}
            onSelect={this.onSelect}
            validRange={[moment('20111031'), moment()]}
          />
        </Scrollbars>
      </div>
    )
  }
}

export default Settings
