import React from 'react'
import { /* BackTop, */ Layout } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import TopBar from 'components/LayoutComponents/TopBar'
import Menu from 'components/LayoutComponents/Menu'
import Settings from 'components/LayoutComponents/Settings'

const mapStateToProps = ({ settings }) => ({
  isBorderless: settings.isBorderless,
  isCalendarEnabled: settings.isCalendarEnabled,
  isSquaredBorders: settings.isSquaredBorders,
  isFixedWidth: settings.isFixedWidth,
  isMenuShadow: settings.isMenuShadow,
  isMenuTop: settings.isMenuTop,
})

@withRouter
@connect(mapStateToProps)
class MainLayout extends React.PureComponent {
  render() {
    const {
      children,
      isBorderless,
      isSquaredBorders,
      isFixedWidth,
      isMenuShadow,
      isMenuTop,
      isCalendarEnabled,
    } = this.props
    return (
      <Layout
        className={classNames({
          settings__borderLess: isBorderless,
          settings__squaredBorders: isSquaredBorders,
          settings__fixedWidth: isFixedWidth,
          settings__menuShadow: isMenuShadow,
          settings__menuTop: isMenuTop,
        })}
      >
        {isCalendarEnabled && (
          <div
            className="d-none d-xl-block"
            style={{
              position: 'fixed',
              right: 0,
              background: 'white',
              zIndex: 1,
              top: 0,
              fontSize: 23,
              height: '100vh',
              border: '1px solid #e4e9f0',
            }}
          >
            <div
              className="btn-group-vertical"
              style={{
                marginTop: '70px',
                zIndex: 1,
                position: 'static',
                marginLeft: 10,
                marginRight: 10,
              }}
            >
              <button
                type="button"
                style={{
                  fontSize: '20px',
                  borderRadius: '50px',
                  border: 'none',
                }}
                onClick={() => {
                  const { dispatch } = this.props
                  dispatch({
                    type: 'settings/SET_STATE',
                    payload: {
                      isSettingsOpen: true,
                    },
                  })
                }}
                className="btn btn-light"
              >
                <i className="fa fa-calendar" />
              </button>
            </div>
          </div>
        )}
        {isCalendarEnabled && (
          <button
            type="button"
            style={{
              fontSize: '20px',
              borderRadius: '50px',
              border: 'none',
              position: 'fixed',
              bottom: '20px',
              right: '20px',
              zIndex: 2,
            }}
            onClick={() => {
              const { dispatch } = this.props
              dispatch({
                type: 'settings/SET_STATE',
                payload: {
                  isSettingsOpen: true,
                },
              })
            }}
            className="btn btn-primary d-block d-xl-none d-lg-block"
          >
            <i className="fa fa-calendar" />
          </button>
        )}

        {/* <BackTop /> */}
        <Menu />
        <Settings />
        <Layout>
          <Layout.Header style={{ zIndex: 5 }}>
            <TopBar />
          </Layout.Header>
          <Layout.Content style={{ height: '100%', position: 'relative' }}>
            <div className="utils__content">{children}</div>
          </Layout.Content>
        </Layout>
      </Layout>
    )
  }
}

export default MainLayout
