import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import login, { currentAccount, logout } from 'services/user'
import { getLeftMenuData, getTopMenuData } from 'services/menu'
import actions, { forgotPasswordSendCode, forgotPasswordSubmit } from './actions'

export function* LOGIN({ payload }) {
  const { email, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      newPassReq: false,
    },
  })
  const success = yield call(login, email, password, payload)
  if (success.success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
  } else if (success.newPassReq) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
        newPassReq: true,
        userDetails: success.userDetails,
      },
    })
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* FORGETPASSWORD_SEND_CODE({ payload }) {
  const { username } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      forgetPasswordLoading: true,
    },
  })

  const response = yield call(forgotPasswordSendCode, username)

  if (response.status === 403) {
    if (response.err.code === 'UserNotFoundException') {
      notification.warning({
        message: 'User not found',
        description: 'Email is not registered to our platform',
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          forgetPasswordLoading: false,
        },
      })
    } else {
      notification.warning({
        message: response.err.name,
        description: response.err.message,
      })
      yield put({
        type: 'user/SET_STATE',
        payload: {
          forgetPasswordLoading: false,
        },
      })
    }
  } else if (response.status === 200) {
    notification.success({
      message: 'Recovery Code sent',
      description: 'Please check your email for the recovery code',
    })
    return yield put({
      type: 'user/SET_STATE',
      payload: {
        forgetPasswordLoading: false,
        forgetPasswordUsername: username,
        forgetPasswordState: 'codeSent',
      },
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      forgetPasswordLoading: false,
    },
  })
}

export function* FORGOTPASSWORD_SUBMIT({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      forgetPasswordLoading: true,
    },
  })

  const { code, password, username } = payload

  const response = yield call(forgotPasswordSubmit, username, code, password)

  if (response.status === 403) {
    notification.warning({
      message: response.err.name,
      description: response.err.message,
    })
  } else if (response.status === 200) {
    notification.success({
      message: 'Password Changed',
      description: 'Password Changed successfully, please login using your new password.',
    })
    return yield put({
      type: 'user/SET_STATE',
      payload: {
        forgetPasswordLoading: false,
        forgetPasswordState: 'passwordChanged',
      },
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      forgetPasswordLoading: false,
    },
  })
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(currentAccount)

  if (response.status === true) {
    const { fullName, accessKeyId, secretAccessKey, sessionToken } = response
    /* console.log(
      'API Access Tokens',
      'accessKeyId:',
      accessKeyId,
      'secretAccessKey:',
      secretAccessKey,
      'sessionToken:',
      sessionToken,
    ) */
    let userType = 'Member'
    const { groups } = response
    // console.log('groups', response)
    if (groups) {
      groups.map((group) => {
        if (group === 'sa') {
          userType = 'Superadmin'
        } else if (group === 'member') {
          if (groups.length === 1) {
            userType = 'Member'
          }
        }
        return false
      })
    }

    let menuLeftData = yield call(getLeftMenuData, userType)
    const menuTopData = yield call(getTopMenuData)
    menuLeftData = menuLeftData.filter((datum) => datum.display !== false)
    notification.success({
      message: `Logged In`,
      description: '',
    })
    yield put({
      type: 'menu/SET_STATE',
      payload: {
        menuLeftData,
        menuTopData,
      },
    })
    yield put({
      type: 'menu/SET_STATE',
      payload: {
        menuLeftData,
        menuTopData,
      },
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        name: fullName,
        accessKeyId,
        secretAccessKey,
        sessionToken,
        role: userType,
        authorized: true,
        details: response,
      },
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      pauseLoading: true,
      loading: false,
    },
  })
}
// function* sleep(time) {
//   yield new Promise(resolve => setTimeout(resolve, time))
// }
export function* LOGOUT() {
  yield call(logout)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
    },
  })
}

// export function* GET_DATA() {
//   const role = yield select(getUser)
//   console.log('role', role)
//   let menuLeftData = yield call(getLeftMenuData, role)
//   const menuTopData = yield call(getTopMenuData)
//   menuLeftData = menuLeftData.filter(datum => datum.display !== false)
//   yield put({
//     type: 'menu/SET_STATE',
//     payload: {
//       menuLeftData,
//       menuTopData,
//     },
//   })
//   yield put({
//     type: 'menu/SET_STATE',
//     payload: {
//       menuLeftData,
//       menuTopData,
//     },
//   })
// }

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.FORGETPASSWORD_SEND_CODE, FORGETPASSWORD_SEND_CODE),
    takeEvery(actions.FORGOTPASSWORD_SUBMIT, FORGOTPASSWORD_SUBMIT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
