export default {
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://api2.circadia.io',
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_WLAItiF9J',
    APP_CLIENT_ID: '2ifse5bi9qktofuoqli0p7lol5',
    IDENTITY_POOL_ID: 'us-east-1:7a239b88-07d4-4548-8b7a-a80a2b70efb0',
  },
  apiPaths: {},
}
