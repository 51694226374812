import { API } from 'aws-amplify'
import moment from 'moment-timezone'

const actions = {
  SET_STATE: 'profile/SET_STATE',
  GET_MEMBER_DETAIL: 'profile/GET_MEMBER_DETAIL',
  GET_MEMBER_SLEEP_STAGES_HISTORY: 'profile/GET_MEMBER_SLEEP_STAGES_HISTORY',
  GET_MEMBER_BODY_CLOCK: 'profile/GET_MEMBER_BODY_CLOCK',
  GET_MEMBER_TRENDS: 'profile/GET_MEMBER_TRENDS',
  GET_WEEKLY_REPORT_DATA: 'profile/GET_WEEKLY_REPORT_DATA',
}
function getMyInIt(userUID) {
  const myInit = {
    // OPTIONAL
    headers: {}, // OPTIONAL
    queryStringParameters: {
      // OPTIONAL
      user_uid: userUID,
    },
  }
  return myInit
}
function getMyInItPost(userUID) {
  const myInit = {
    // OPTIONAL
    body: { user_uid: userUID },
    headers: {}, // OPTIONAL
  }
  return myInit
}
function SleepStagesHistory(userUID, date) {
  const myInit = {
    // OPTIONAL
    queryStringParameters: {
      user_uid: userUID,
      date_of_sleep: date,
    },
    headers: {}, // OPTIONAL
  }
  return myInit
}
export const getMemberDetail = userUID =>
  API.get('virtualClinic', `/virtualclinic-temp/v1/memberDetail`, getMyInIt(userUID))
    .then(res => res)
    .catch(err => {
      console.log('Error Loading Member Detail', err)
      return []
    })

export const getMemberSleepSessions = userUID =>
  API.get('virtualClinic', `/virtualclinic-temp/v1/memberSleepSessions`, getMyInIt(userUID))
    .then(res => res)
    .catch(err => {
      console.log('Error loading MemberSleepSessions', err)
      return []
    })

export const getMemberCSIHistory = userUID =>
  API.post('virtualClinic', `/virtualclinic-temp/v1/memberCSIHistory`, getMyInItPost(userUID))
    .then(res => res)
    .catch(err => {
      console.log('Error Loading memberCSIHistory', err)
      return []
    })

export const getMemberSleepStagesHistory = (userUID, date) =>
  API.get('virtualClinic', '/sleep-session-v1/memberSleepStages', SleepStagesHistory(userUID, date))
    .then(res => res)
    .catch(err => {
      console.log('Error Loading getMemberSleepStagesHistory', err.response)
      return { status: 404 }
    })

export const getMemberBodyClock = dataObj =>
  API.get('virtualClinic', '/vc-computation/circadia_analysis_graphs', {
    headers: {},
    queryStringParameters: {
      user_id: dataObj.userUID,
      graph: dataObj.graph,
    },
  })
    .then(res => {
      const { img } = res
      const { graph } = dataObj

      if (graph === 'process_c') {
        return res
      }
      if (img) {
        return img
      }

      return res
    })
    .catch(err => {
      console.log('bodyclocktimeline', err)
      return {}
    })

export const getMetricsHistory = userUID =>
  API.post('virtualClinic', `/virtualclinic-temp/v1/memberMetricsHistory`, {
    // OPTIONAL
    headers: {}, // OPTIONAL
    body: {
      user_uid: userUID,
      date: moment().format('YYYY-MM-DD'),
    },
  })
    .then(res => res)
    .catch(err => {
      console.log('Error Loading memberSleepSessions', err)
      return []
    })

export const getLastSleepStagesHistory = userUID =>
  API.get('virtualClinic', '/sleep-session-v1/memberSleepStages', {
    // OPTIONAL
    queryStringParameters: {
      user_uid: userUID,
    },
    headers: {}, // OPTIONAL
  })
    .then(res => res)
    .catch(err => {
      const { response } = err
      console.log('error getLastSleepStagesHistory', !response ? err : response)
      return { status: 404 }
    })
export const convertTime = (date, timezone = 'Europe/London') => {
  const m = moment.utc(date) // parse input as UTC
  // console.log('MMMM', m)
  const newDate = m
    .tz(timezone)
    .format('MM/DD/YYYY HH:mm:ss') /* 
  console.log('newDate', newDate) */
  return newDate
}
export const convertTimeOffset = (date, offset, format = 'MM/DD/YYYY HH:mm:ss') => {
  const m = moment.utc(date) // parse input as UTC
  // console.log('m', m, timezone)
  if (offset === undefined || offset === null) {
    const local = m.local().format('MM/DD/YYYY HH:mm:ss')
    // console.log('local', local, timezone)
    return local
  }
  const newDate = m.utcOffset(offset)
  return newDate.format(format)
}

export const getOffsetFromRecommendation = recommendation => {
  recommendation = recommendation.split('\n')
  recommendation = recommendation[1] ? recommendation[1] : ''
  recommendation = recommendation.split(' ')
  recommendation = recommendation[2] ? parseFloat(recommendation[2], 10) : 0

  return recommendation
}
export default actions
