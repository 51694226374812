import React from 'react'
// import { Button } from 'antd'
// import { FormattedMessage } from 'react-intl'
// import HomeMenu from './HomeMenu'
// import ProjectManagement from './ProjectManagement'
// import IssuesHistory from './IssuesHistory'
// import LiveSearch from './LiveSearch'
// import BitcoinPrice from './BitcoinPrice'
import ProfileMenu from './ProfileMenu'
// import LanguageSelector from './LanguageSelector'
import styles from './style.module.scss' /* ---* Uncomment to use Breadcrumbs *---
import Breadcrumbs from '../Breadcrumbs' */

class TopBar extends React.Component {
  render() {
    return (
      <div className={styles.topbar}>
        <div className="d-none d-md-block mr-4">
          {/* 
          <img src="resources/images/circadia.png" style={{ height: '30px' }} alt="" /> */}
          {/*  ---* Uncomment to use Breadcrumbs *---
          <Breadcrumbs /> */}
        </div>
        <div className="d-block d-md-none d-lg-none mr-4">
          <img src="resources/images/circadia-mobile.png" style={{ height: '30px' }} alt="" />
          {/*  ---* Uncomment to use Breadcrumbs *---
          <Breadcrumbs /> */}
        </div>
        <div className="mr-auto" />

        <div className="mr-4" />
        <div className="mr-4" />

        <div className="mr-4">{/*  <HomeMenu /> */}</div>
        <ProfileMenu />
      </div>
    )
  }
}

export default TopBar
