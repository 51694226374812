import { API } from 'aws-amplify'

const actions = {
  SET_STATE: 'device/SET_STATE',
  GET_DEVICES: 'device/GET_DEVICES',
  GET_DEVICE_ACTIVITY: 'device/GET_DEVICE_ACTIVITY',
}
export const getAllDevices = async () =>
  API.get('virtualClinic', '/virtualclinic-temp/v1/allDevices')
    .then(res => res)
    .catch(err => {
      console.log('Error Loading Device List', err)
      return []
    })

export const getDeviceActivity = async deviceID =>
  API.get('virtualClinic', '/virtualclinic-temp/v1/deviceActivity', getMyInIt(deviceID))
    .then(res => res)
    .catch(err => {
      console.log('Error Loading Device Activity', err)
      return []
    })

function getMyInIt(deviceID) {
  const myInit = {
    // OPTIONAL
    headers: {}, // OPTIONAL
    queryStringParameters: {
      // OPTIONAL
      device_uid: deviceID,
    },
  }
  return myInit
}
export default actions
