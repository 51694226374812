import { all, put, call, takeEvery } from 'redux-saga/effects'
import actions, { getAllMembers } from './actions'

export function* GET_MEMBERS() {
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const members = yield call(getAllMembers)
  const activeMembers = members.filter(member => member.device_id)
  yield put({
    type: 'member/SET_STATE',
    payload: {
      loading: false,
      members,
      activeMembers,
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_MEMBERS, GET_MEMBERS)])
}
