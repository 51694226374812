import { all, put, call, takeEvery } from 'redux-saga/effects'
import actions, { getAllDevices, getDeviceActivity } from './actions'

export function* GET_DEVICES() {
  yield put({
    type: 'device/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const devices = yield call(getAllDevices)
  yield put({
    type: 'device/SET_STATE',
    payload: {
      loading: false,
      devices,
    },
  })
}

export function* GET_DEVICE_ACTIVITY({ payload }) {
  const { deviceID } = payload
  yield put({
    type: 'device/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const deviceActivity = yield call(getDeviceActivity, deviceID)
  yield put({
    type: 'device/SET_STATE',
    payload: {
      loading: false,
      deviceActivity,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_DEVICES, GET_DEVICES),
    takeEvery(actions.GET_DEVICE_ACTIVITY, GET_DEVICE_ACTIVITY),
  ])
}
