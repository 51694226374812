import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import moment from 'moment'
import actions, {
  getMemberDetail,
  getMemberCSIHistory,
  getMemberSleepStagesHistory,
  getMemberBodyClock,
  getMemberSleepSessions,
  getMetricsHistory,
  getOffsetFromRecommendation,
  getLastSleepStagesHistory,
  convertTimeOffset,
} from './actions'

const getProfile = (state) => state.profile

export function* GET_MEMBER_DETAIL({ payload }) {
  const { userUID } = payload
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const memberSleepSessions = yield call(getMemberSleepSessions, userUID)
  const memberDetail = yield call(getMemberDetail, userUID)
  const memberCSIHistory = yield call(getMemberCSIHistory, userUID)
  const memberBodyClock = yield call(getMemberBodyClock, userUID)
  const memberSleepStagesHistory = yield call(GET_MEMBER_SLEEP_STAGES_HISTORY, {
    payload: { userUID },
  })
  console.log('memberSleepStagesHistory', memberSleepStagesHistory)
  // const memberSleepStagesHistory = yield call(
  //   getMemberSleepStagesHistory,
  //   userUID,

  //   moment().format('YYYY-MM-DD'),
  // )
  // const memberBodyClock = yield call(getMemberBodyClock, userUID)
  const bodyclockSummary = yield call(formatBodyClockSummary, memberBodyClock)
  const lastBodyclock = bodyClockDiff(bodyclockSummary, memberDetail)
  let Levels = {
    metrics: {
      TotalSleepTime: 0,
      SleepEfficiency: 0,
    },
  }
  if (memberSleepStagesHistory.levels) {
    Levels = memberSleepStagesHistory.levels
  }
  const { metrics } = Levels
  const { TotalSleepTime, SleepEfficiency } = metrics
  let finalCSI = 0
  if (memberCSIHistory.length > 0) {
    finalCSI = Math.round(memberCSIHistory[0].csi_final)
  }
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      loading: false,
      lastCSI: finalCSI,
      lastTST: convertMinsToHrsMins(TotalSleepTime),
      lastSE: Math.round(SleepEfficiency),
      lastBodyclock,
      memberCSI: finalCSI,
      memberSleepSessions,
      memberDetail,
      memberCSIHistory,
    },
  })
  yield call(GET_MEMBER_TRENDS, { payload: { userUID } })
  yield call(GET_MEMBER_BODY_CLOCK, { payload: { userUID } })
}

export function* GET_MEMBER_SLEEP_STAGES_HISTORY({ payload }) {
  const { userUID, date } = payload

  if (!userUID) {
    return false
  }
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      calendarDate: date,
      tabLoading: true,
    },
  })
  const memberSleepStagesHistory = date
    ? yield call(getMemberSleepStagesHistory, userUID, date)
    : yield call(getLastSleepStagesHistory, userUID)
  const memberCSIHistory = yield call(getMemberCSIHistory, userUID)
  let memberCSI = 0
  Object.keys(memberCSIHistory).map((key) => {
    if (memberCSIHistory[key].date === date) {
      memberCSI = memberCSIHistory[key].csi_final
    }
    return null
  })
  if (memberSleepStagesHistory.status === 404) {
    yield put({
      type: 'profile/SET_STATE',
      payload: {
        memberSleepStagesHistory: [],
        noiseGraphData: [],
        motionGraphData: [],
        respiratoryGraphData: [],
        respiratoryVariableGraphData: [],
        memberCSI: 0,
        presenceData: [],
        hypnogramData: [],
        lightAMChartData: [],
        tabLoading: false,
      },
    })
    return false
  }
  const { levels } = memberSleepStagesHistory

  const { env, data } = levels

  const hypnogramData = []
  const userTimezone = +memberSleepStagesHistory.tz_diff || 0
  data.map((datum) => {
    let dateTime = new Date(convertTimeOffset(datum.created_at))
    if (userTimezone) {
      dateTime = new Date(convertTimeOffset(datum.created_at, userTimezone))
    }
    if (datum.stage === 'W') {
      hypnogramData.push({
        t: new Date(dateTime),
        y: 4,
        state: 'Wake',
      })
    } else if (datum.stage === 'D') {
      hypnogramData.push({
        t: new Date(dateTime),
        y: 1,
        state: 'Deep',
      })
    } else if (datum.stage === 'R') {
      hypnogramData.push({
        t: new Date(dateTime),
        y: 3,
        state: 'REM',
      })
    } else {
      hypnogramData.push({
        t: new Date(dateTime),
        y: 2,
        state: 'Light',
      })
    }

    return true
  })

  const noiseGraphData = []
  const motionGraphData = []
  const respiratoryGraphData = []
  const respiratoryVariableGraphData = []
  const lightAMChartData = []
  const presenceData = []
  let lastPresenceDate
  let lastRRValue = 0
  let lastRRv1Value = 0
  let lastMotionValue = 0
  let lastNoiseValue = 0
  let absence = 0
  env.map((datum, count) => {
    let dateTime = new Date(convertTimeOffset(datum.packet_dt))

    if (userTimezone) {
      dateTime = new Date(convertTimeOffset(datum.packet_dt, userTimezone))
    }
    if (datum.presence === 0) {
      absence += 30
      if (!lastPresenceDate) {
        lastPresenceDate = dateTime
      }
      noiseGraphData.push({
        t: dateTime,
        y: '0',
      })
      respiratoryGraphData.push({
        t: dateTime,
        y: '0',
      })
      respiratoryVariableGraphData.push({
        t: dateTime,
        y: '0',
      })
      lightAMChartData.push({
        t: dateTime,
      })
      motionGraphData.push({
        t: dateTime,
        y: '0',
      })

      return true
    }
    if (lastPresenceDate) {
      let endDate = new Date(convertTimeOffset(env[count - 1].packet_dt))
      if (userTimezone) {
        endDate = new Date(convertTimeOffset(env[count - 1].packet_dt, userTimezone))
      }
      if (
        lastPresenceDate.toDateString() === endDate.toDateString() &&
        lastPresenceDate.toTimeString() === endDate.toTimeString()
      ) {
        endDate = new Date(moment(endDate).add(20, 'second'))
      }
      presenceData.push({
        startDate: lastPresenceDate,
        endDate,
      })
      lastPresenceDate = null
    }
    if (datum.noice_max) {
      lastNoiseValue = datum.noice_max
    }
    noiseGraphData.push({
      t: dateTime,
      y: lastNoiseValue,
    })
    if (datum.trackerRR) {
      lastRRValue = datum.trackerRR
    }
    respiratoryGraphData.push({
      t: dateTime,
      y: lastRRValue,
      value: lastRRValue,
    })
    if (datum.trackerRR_V1) {
      lastRRv1Value = datum.trackerRR_V1
    }
    respiratoryVariableGraphData.push({
      t: dateTime,
      y: lastRRv1Value,
      value: lastRRv1Value,
    })
    lightAMChartData.push({
      t: dateTime,
      r: datum.illum_r_max,
      g: datum.illum_g_max,
      b: datum.illum_b_max,
      w: datum.illum_w_max,
    })
    if (datum.tracker_mvmnt_V2_amp) {
      lastMotionValue = datum.tracker_mvmnt_V2_amp
    }
    motionGraphData.push({
      t: dateTime,
      y: lastMotionValue,
    })
    return true
  })
  // let absence = 0
  // presenceData.map(datum => {
  //   // absence += moment(datum.endDate).diff(moment(datum.startDate), 'minute')
  //   absence += getMinutesBetweenDates(datum.startDate, datum.endDate)
  //   return true
  // })
  absence = Math.trunc(absence / 60)
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      calendarDate: memberSleepStagesHistory.date_of_sleep,
      memberSleepStagesHistory,
      noiseGraphData,
      motionGraphData,
      respiratoryGraphData,
      respiratoryVariableGraphData,
      absence,
      memberCSI,
      lightAMChartData,
      presenceData,
      hypnogramData,
      tabLoading: false,
    },
  })
  return memberSleepStagesHistory
}
// const getMinutesBetweenDates = (startDate, endDate) => {
//   const diff = endDate.getTime() - startDate.getTime()
//   return diff / 60000
// }
export function* GET_MEMBER_BODY_CLOCK({ payload }) {
  const { userUID } = payload
  const bodyClockObj = {
    userUID,
    graph: 'bodyclock_points',
  }
  const bodyClockImageObj = {
    userUID,
    graph: 'bodyclock',
  }
  const bodyClockProccessCObj = {
    userUID,
    graph: 'process_c',
  }
  const bodyClockPRCObj = {
    userUID,
    graph: 'prc',
  }
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      tabLoading: true,
      memberBodyClockData: [],
      bodyclockSummary: [],
      memberBodyClockImage: '',
      memberProcessCImage: '',
      memberPRCImage: '',
      memberBodyClockGraphData: [],
    },
  })
  const [
    memberBodyClockData,
    memberPRCImage,
    memberBodyClockImage,
    memberProcessCRequest,
  ] = yield all([
    call(getMemberBodyClock, bodyClockObj),
    call(getMemberBodyClock, bodyClockPRCObj),
    call(getMemberBodyClock, bodyClockImageObj),
    call(getMemberBodyClock, bodyClockProccessCObj),
  ])
  const memberProcessCImage = memberProcessCRequest.img ? memberProcessCRequest.img : ''
  const recommendation = memberProcessCRequest.recommendation
    ? memberProcessCRequest.recommendation
    : ''
  const bodyClockOffset = getOffsetFromRecommendation(recommendation)
  const CProcessData = yield call(formatCProcessData, memberBodyClockData)
  const timelineData = yield call(formatTimelineData, memberBodyClockData)
  const bodyclockSummary = yield call(formatBodyClockSummary, memberBodyClockData, bodyClockOffset)

  const formattedBodyClockData = { datasets: timelineData.concat(CProcessData) }
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      memberBodyClockData,
      bodyclockSummary,
      memberBodyClockImage,
      memberProcessCImage,
      memberPRCImage,
      memberBodyClockGraphData: formattedBodyClockData,
      tabLoading: false,
    },
  })
  return bodyclockSummary
}

export function* GET_MEMBER_TRENDS({ payload }) {
  const { userUID } = payload
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      tabLoading: true,
    },
  })

  const trendsData = yield call(getMetricsHistory, userUID)
  const { weeklyTrendsData, monthlyTrendsData, fortnightTrendsData } = formatTrendsData(trendsData)

  yield put({
    type: 'profile/SET_STATE',
    payload: {
      monthlyTrendsData,
      weeklyTrendsData,
      fortnightTrendsData,
      tabLoading: false,
    },
  })
}

export const formatTrendsData = (data) => {
  const weeklyTrendsData = []
  const monthlyTrendsData = []
  const fortnightTrendsData = []
  Object.keys(data).map((key) => {
    let { weekDataSum, weekDataAvg, lastWeekDataSum, lastWeekDataAvg } = 0
    const weekData = data[key].slice(0, 7)
    const lastWeekData = data[key].slice(7, 14)
    let allWeekValues = weekData.filter((datum) => datum.value && true)
    let allLastWeekValues = lastWeekData.filter((datum) => datum.value && true)
    if (key === 'bedTime' || key === 'wakeTime') {
      allWeekValues = allWeekValues.map((datum) => {
        const { date } = datum
        const time = moment(datum.value).format('hh:mm:ss')
        const dateTime = moment(`${date} ${time}`).valueOf()
        return dateTime
      })
      allLastWeekValues = allLastWeekValues.map((datum) => {
        const { date } = datum
        const time = moment(datum.value).format('hh:mm:ss')
        const dateTime = moment(`${date} ${time}`).valueOf()
        return dateTime
      })

      weekDataSum = allWeekValues.length > 0 ? allWeekValues.reduce((a, b) => a + b) : 0
      weekDataAvg = weekDataSum / allWeekValues.length

      lastWeekDataSum = allLastWeekValues.length > 0 ? allLastWeekValues.reduce((a, b) => a + b) : 0
      lastWeekDataAvg = lastWeekDataSum / allLastWeekValues.length

      return false
    }
    allWeekValues = allWeekValues.map((datum) => datum.value)
    allLastWeekValues = allLastWeekValues.map((datum) => datum.value)

    weekDataSum = allWeekValues.length > 0 ? allWeekValues.reduce((a, b) => a + b) : 0
    weekDataAvg = weekDataSum / allWeekValues.length

    lastWeekDataSum = allLastWeekValues.length > 0 ? allLastWeekValues.reduce((a, b) => a + b) : 0
    lastWeekDataAvg = lastWeekDataSum / allLastWeekValues.length

    let { monthDataSum, monthDataAvg, lastMonthDataSum, lastMonthDataAvg } = 0

    const monthData = data[key].slice(0, 30)
    const lastMonthData = data[key].slice(30, 60)

    const monthGraphData = monthData.map((datum) => ({ t: new Date(datum.date), y: datum.value }))
    const weekGraphData = weekData.map((datum) => ({ t: new Date(datum.date), y: datum.value }))

    let allMonthValues = monthData.filter((datum) => datum.value && true)
    let allLastMonthValues = lastMonthData.filter((datum) => datum.value && true)

    allMonthValues = allMonthValues.map((datum) => datum.value)
    allLastMonthValues = allLastMonthValues.map((datum) => datum.value)

    monthDataSum = allMonthValues.length > 0 ? allMonthValues.reduce((a, b) => a + b) : 0
    monthDataAvg = monthDataSum / allMonthValues.length

    lastMonthDataSum =
      allLastMonthValues.length > 0 ? allLastMonthValues.reduce((a, b) => a + b) : 0
    lastMonthDataAvg = lastMonthDataSum / allLastMonthValues.length

    let { fortnightDataSum, fortnightDataAvg, lastFortnightDataSum, lastFortnightDataAvg } = 0

    const fortnightData = data[key].slice(0, 14)
    const lastFortnightData = data[key].slice(14, 28)

    let allFortnightValues = fortnightData.filter((datum) => datum.value && true)
    let allLastFortnightValues = lastFortnightData.filter((datum) => datum.value && true)

    allFortnightValues = allFortnightValues.map((datum) => datum.value)
    allLastFortnightValues = allLastFortnightValues.map((datum) => datum.value)

    fortnightDataSum =
      allFortnightValues.length > 0 ? allFortnightValues.reduce((a, b) => a + b) : 0
    fortnightDataAvg = fortnightDataSum / allFortnightValues.length

    lastFortnightDataSum =
      allLastFortnightValues.length > 0 ? allLastFortnightValues.reduce((a, b) => a + b) : 0
    lastFortnightDataAvg = lastFortnightDataSum / allLastFortnightValues.length

    const fortnightGraphData = fortnightData.map((datum) => ({
      t: new Date(datum.date),
      y: datum.value,
    }))

    let metricName = ''

    if (key === 'sleep_efficiency') {
      metricName = 'Efficiency [%]'
    } else if (key === 'time_to_fall_asleep') {
      metricName = 'WASO [minutes]'
    } else if (key === 'time_awaken') {
      metricName = 'Times Awaken'
    } else if (key === 'total_sleep_time') {
      metricName = 'TST [hours]'
    } else if (key === 'sleep_on_set_latency') {
      metricName = 'Latency [minutes] '
    } else if (key === 'percent_rem') {
      metricName = 'REM [%]'
    } else if (key === 'percent_light') {
      metricName = 'Light [%]'
    } else if (key === 'percent_deep') {
      metricName = 'Deep [%]'
    } else if (key === 'csi') {
      metricName = 'CSI [%]'
    }
    fortnightTrendsData.push({
      metricKey: key,
      metric: metricName,
      weekData,
      graphData: fortnightGraphData,
      lastAvg: Math.round(lastFortnightDataAvg),
      avg: Math.round(fortnightDataAvg),
      trend: fortnightDataAvg > lastFortnightDataAvg ? 'positive' : 'negative',
      max: Math.round(Math.max(...allFortnightValues)),
      min: Math.round(Math.min(...allFortnightValues)),
    })
    weeklyTrendsData.push({
      metricKey: key,
      metric: metricName,
      weekData,
      graphData: weekGraphData,
      lastAvg: Math.round(lastWeekDataAvg),
      avg: Math.round(weekDataAvg),
      trend: weekDataAvg > lastWeekDataAvg ? 'positive' : 'negative',
      max: Math.round(Math.max(...allWeekValues)),
      min: Math.round(Math.min(...allWeekValues)),
    })
    monthlyTrendsData.push({
      metricKey: key,
      metric: metricName,
      monthData,
      graphData: monthGraphData,
      lastAvg: Math.round(lastMonthDataAvg),
      avg: Math.round(monthDataAvg),
      trend: monthDataAvg > lastMonthDataAvg ? 'positive' : 'negative',
      max: Math.round(Math.max(...allMonthValues)),
      min: Math.round(Math.min(...allMonthValues)),
    })
    return true
  })
  return { weeklyTrendsData, monthlyTrendsData, fortnightTrendsData }
}

export const formatBodyClockSummary = (data) => {
  if (!data.timeline) {
    return []
  }
  const { timeline } = data
  const formattedBodyClockSummary = []
  let count = 1
  timeline.map((temp) => {
    if (
      temp.title === 'Melatonin Stop' ||
      temp.title === 'Highest Alertness' ||
      temp.title === 'Greatest Strength' ||
      temp.title === 'Melatonin Start'
    ) {
      count += 1
      return formattedBodyClockSummary.push({
        key: count,
        head: `${temp.title} [time]`,
        value: temp.time_hhmm,
      })
    }

    return false
  })

  return formattedBodyClockSummary
}

export const bodyClockDiff = (bodyclockSummary, memberDetail) => {
  if (bodyclockSummary.length > 0) {
    const melatoninStop = bodyclockSummary[0].value
    const desiredWakeTime = memberDetail.desired_wake_time
    const time = moment.duration('00:30:00')
    const date = moment(`2/27/2019 ${melatoninStop}`).subtract(time)
    const diffHours = moment.duration(date.diff(moment(`2/27/2019 ${desiredWakeTime}`)))
    let hours = diffHours.hours()
    if (Math.abs(diffHours.minutes()) > 30) {
      if (hours > 0) {
        hours += 1
      } else {
        hours -= 1
      }
    }
    const bodyClockDifference = `${hours}h`

    return bodyClockDifference
  }
  return '0h'
}

const formatTimelineData = (data) => {
  if (!data.timeline) {
    return []
  }
  const timelineData = data.timeline
  const formattedTimelineData = []
  let trigger = 0
  timelineData.map((temp, count) => {
    if (count > 0) {
      if (
        new Date(`2019-01-13 ${timelineData[count - 1].time_hhmm}`).getHours() >
        new Date(`2019-01-13 ${timelineData[count].time_hhmm}`).getHours()
      ) {
        trigger += 1
      }
    }
    let color = 'orange'
    if (temp.title === 'Lowest Body Temp') {
      color = 'rgba(75,192,192,1)'
    } else if (temp.title === 'Melatonin Stop') {
      color = 'Red'
    } else if (temp.title === 'Highest Alertness') {
      color = 'Purple'
    } else if (temp.title === 'Fastest Reaction Time') {
      color = 'Brown'
    } else if (temp.title === 'Greatest Strength') {
      color = 'Pink'
    } else if (temp.title === 'Highest Body Temp') {
      color = 'Gray'
    } else if (temp.title === 'Melatonin Start') {
      color = '#33FF99'
    } else if (temp.title === 'Lowest Body Temp') {
      color = '#6699FF'
    }
    return formattedTimelineData.push({
      type: 'scatter',
      label: temp.title,
      backgroundColor: color,
      pointBorderColor: color,
      pointBackgroundColor: '#fff',
      pointBorderWidth: 10,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: color,
      pointHoverBorderColor: color,
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      fill: false,
      pointHitRadius: 10,
      yAxisID: 'y-axis-2',
      data: [{ x: new Date(`2019-01-${13 + trigger} ${temp.time_hhmm}`), y: temp.process_c_value }],
    })
  })
  return formattedTimelineData
}

const formatCProcessData = (data) => {
  if (!data.process_c_with_ts_hhmm) {
    return []
  }
  const processCData = data.process_c_with_ts_hhmm
  const formattedProcessCData = []
  let trigger = 0
  processCData.map((temp, count) => {
    if (count > 0) {
      if (
        new Date(`2019-01-13 ${processCData[count - 1].timestamp_hhmm}`).getHours() >
        new Date(`2019-01-13 ${processCData[count].timestamp_hhmm}`).getHours()
      ) {
        trigger += 1
      }
    }

    return formattedProcessCData.push({
      x: new Date(`2019-01-${13 + trigger} ${temp.timestamp_hhmm}`),
      y: temp.process_c,
    })
  })
  const formattedGraphData = [
    {
      type: 'line',
      fill: false,
      spanGaps: true,
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      label: 'Process C',
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: 'rgba(75,192,192,1)',
      pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: '#fff',
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointRadius: 1,
      pointHitRadius: 0,
      yAxisID: 'y-axis-2',
      data: formattedProcessCData,
    },
  ]
  return formattedGraphData
}

export const convertMinsToHrsMins = (mins) => {
  // do not include the first validation check if you want, for example,
  // getTimeFromMins(1530) to equal getTimeFromMins(90) (i.e. mins rollover)

  if (mins >= 24 * 60 || mins < 0) {
    throw new RangeError('Valid input should be greater than or equal to 0 and less than 1440.')
  }
  const h = (mins / 60) | 0 // eslint-disable-line no-bitwise
  const m = mins % 60 | 0 // eslint-disable-line no-bitwise
  // if (mins > 30) {
  //   h += 1
  // }
  if (mins === 0) {
    return '0'
  }
  if (h === 0) {
    return `0h ${moment.utc().minutes(m).format('mm[m]')}`
  }
  return moment.utc().hours(h).minutes(m).format('h[h] mm[m]')
}
export function* GET_WEEKLY_REPORT_DATA({ payload }) {
  const { userUID, date } = payload
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      reportDataLoading: true,
    },
  })
  const weeklyReportMetrics = {
    'TST [hours]': [],
    'TTA [minutes]': [],
    'SE [%]': [],
    'SOL [minutes]': [],
    'ROL [minutes]': [],
    'Time in Bed [hours]': [],
    'WASO [minutes]': [],
    'Absence [minutes]': [],
    'CSI [%]': [],
  }
  const weeklyReportData = { Wake: [], REM: [], Light: [], Deep: [] }

  let memberSleepStagesHistory
  memberSleepStagesHistory = yield call(getLastSleepStagesHistory, userUID)
  const deviceID = memberSleepStagesHistory.device_id
  const profile = yield select(getProfile)
  const { memberCSIHistory } = profile
  const userTimezone = +memberSleepStagesHistory.tz_diff || 0
  const trendsData = yield call(getMetricsHistory, userUID)
  const { weeklyTrendsData, monthlyTrendsData } = formatTrendsData(trendsData)
  const reportSleepSessions = {}
  for (let i = 6; i >= 0; i -= 1) {
    const tempDate = moment(date).subtract(i, 'day').format('YYYY-MM-DD')
    memberSleepStagesHistory = yield call(getMemberSleepStagesHistory, userUID, tempDate)

    let absence = 0
    let csi = 0
    let tta = 0
    // eslint-disable-next-line no-loop-func
    memberCSIHistory.map((datum) => {
      if (datum.date === tempDate) {
        csi = datum.csi_final
      }
      return true
    })
    // eslint-disable-next-line no-loop-func
    monthlyTrendsData.map((datum) => {
      if (datum.metricKey === 'time_awaken') {
        datum.monthData.map((subDatum) => {
          if (subDatum.date === tempDate) {
            tta = subDatum.value
          }
          return false
        })
      }
      return false
    })
    if (memberSleepStagesHistory.status === 404) {
      weeklyReportData.Wake.push(0)
      weeklyReportData.REM.push(0)
      weeklyReportData.Light.push(0)
      weeklyReportData.Deep.push(0)

      reportSleepSessions[tempDate] = {
        count: i,
        sleep: { startTime: null, endTime: null },
      }

      weeklyReportMetrics['ROL [minutes]'].push({
        view: 0,
        value: 0,
      })
      weeklyReportMetrics['Time in Bed [hours]'].push({
        view: 0,
        value: 0,
      })
      weeklyReportMetrics['TST [hours]'].push({
        view: 0,
        value: 0,
      })
      weeklyReportMetrics['SOL [minutes]'].push({
        view: 0,
        value: 0,
      })
      weeklyReportMetrics['SE [%]'].push({
        view: 0,
        value: 0,
      })
      weeklyReportMetrics['WASO [minutes]'].push({
        view: 0,
        value: 0,
      })
      weeklyReportMetrics['TTA [minutes]'].push({
        view: Math.round(tta),
        value: tta,
      })
      weeklyReportMetrics['Absence [minutes]'].push({
        view: 0,
        value: 0,
      })
      weeklyReportMetrics['CSI [%]'].push({
        view: Math.round(csi),
        value: csi,
      })
    } else {
      const { levels } = memberSleepStagesHistory
      const { env } = levels
      let lastPresenceDate
      const reportPresenceData = []
      // eslint-disable-next-line no-loop-func
      env.map((datum, count) => {
        const dateTime = new Date(convertTimeOffset(datum.packet_dt, userTimezone))

        if (datum.presence === 0) {
          absence += 30
          if (!lastPresenceDate) {
            lastPresenceDate = dateTime
          }
          if (lastPresenceDate !== null && count === env.length - 1) {
            let endDate = new Date(convertTimeOffset(env[count - 1].packet_dt, userTimezone))

            if (lastPresenceDate === endDate) {
              endDate += 15000
            }
            reportPresenceData.push({
              startDate: lastPresenceDate,
              endDate,
            })
            lastPresenceDate = null
          }
          return true
        }
        if (lastPresenceDate) {
          let endDate = new Date(convertTimeOffset(env[count - 1].packet_dt, userTimezone))

          if (lastPresenceDate === endDate) {
            endDate += 15000
          }
          reportPresenceData.push({
            startDate: lastPresenceDate,
            endDate,
          })
          lastPresenceDate = null
        }
        return true
      })

      weeklyReportData.Wake.push(
        Math.round(
          memberSleepStagesHistory.levels.summary.W.avg > 0
            ? memberSleepStagesHistory.levels.summary.W.avg
            : 0,
        ),
      )
      weeklyReportData.REM.push(
        Math.round(
          memberSleepStagesHistory.levels.summary.R.avg > 0
            ? memberSleepStagesHistory.levels.summary.R.avg
            : 0,
        ),
      )
      weeklyReportData.Light.push(
        Math.round(
          memberSleepStagesHistory.levels.summary.N.avg > 0
            ? memberSleepStagesHistory.levels.summary.N.avg
            : 0,
        ),
      )
      weeklyReportData.Deep.push(
        Math.round(
          memberSleepStagesHistory.levels.summary.D.avg > 0
            ? memberSleepStagesHistory.levels.summary.D.avg
            : 0,
        ),
      )

      const tempStartTime = moment.utc(memberSleepStagesHistory.startTime)
      const tempEndTime = moment.utc(memberSleepStagesHistory.endTime)

      absence = Math.trunc(absence / 60)
      reportSleepSessions[tempDate] = {
        count: i,
        sleep: { startTime: tempStartTime, endTime: tempEndTime },
      }

      weeklyReportMetrics['ROL [minutes]'].push({
        view: Math.round(memberSleepStagesHistory.levels.metrics.StageRLatency),
        value: memberSleepStagesHistory.levels.metrics.StageRLatency,
      })
      weeklyReportMetrics['Time in Bed [hours]'].push({
        view: convertMinsToHrsMins(memberSleepStagesHistory.levels.metrics.TimeInBed),
        value: memberSleepStagesHistory.levels.metrics.TimeInBed,
      })
      weeklyReportMetrics['TST [hours]'].push({
        view: convertMinsToHrsMins(memberSleepStagesHistory.levels.metrics.TotalSleepTime),
        value: memberSleepStagesHistory.levels.metrics.TotalSleepTime,
      })
      weeklyReportMetrics['SOL [minutes]'].push({
        view: Math.round(memberSleepStagesHistory.levels.metrics.SleepOnsetLatency),
        value: memberSleepStagesHistory.levels.metrics.SleepOnsetLatency,
      })
      weeklyReportMetrics['SE [%]'].push({
        view: Math.round(memberSleepStagesHistory.levels.metrics.SleepEfficiency),
        value: memberSleepStagesHistory.levels.metrics.SleepEfficiency,
      })
      weeklyReportMetrics['WASO [minutes]'].push({
        view: Math.round(memberSleepStagesHistory.levels.metrics.WASO),
        value: memberSleepStagesHistory.levels.metrics.WASO,
      })
      weeklyReportMetrics['TTA [minutes]'].push({
        view: Math.round(tta),
        value: tta,
      })
      weeklyReportMetrics['Absence [minutes]'].push({
        view: Math.round(absence),
        value: absence,
      })
      weeklyReportMetrics['CSI [%]'].push({
        view: Math.round(csi),
        value: csi,
      })
    }
  }
  const avgTST =
    weeklyReportMetrics['TST [hours]'].reduce((a, b) => a + b.value, 0) /
    (weeklyReportMetrics['TST [hours]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0) === 0
      ? 1
      : weeklyReportMetrics['TST [hours]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0))
  const avgTTA =
    weeklyReportMetrics['TTA [minutes]'].reduce((a, b) => a + b.value, 0) /
    (weeklyReportMetrics['TTA [minutes]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0) === 0
      ? 1
      : weeklyReportMetrics['TTA [minutes]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0))
  const avgSE =
    weeklyReportMetrics['SE [%]'].reduce((a, b) => a + b.value, 0) /
    (weeklyReportMetrics['SE [%]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0) === 0
      ? 1
      : weeklyReportMetrics['SE [%]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0))
  const avgSOL =
    weeklyReportMetrics['SOL [minutes]'].reduce((a, b) => a + b.value, 0) /
    (weeklyReportMetrics['SOL [minutes]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0) === 0
      ? 1
      : weeklyReportMetrics['SOL [minutes]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0))
  const avgROL =
    weeklyReportMetrics['ROL [minutes]'].reduce((a, b) => a + b.value, 0) /
    (weeklyReportMetrics['ROL [minutes]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0) === 0
      ? 1
      : weeklyReportMetrics['ROL [minutes]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0))
  const avgTimeInBed =
    weeklyReportMetrics['Time in Bed [hours]'].reduce((a, b) => a + b.value, 0) /
    (weeklyReportMetrics['Time in Bed [hours]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0) === 0
      ? 0
      : weeklyReportMetrics['Time in Bed [hours]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0))
  const avgWASO =
    weeklyReportMetrics['WASO [minutes]'].reduce((a, b) => a + b.value, 0) /
    (weeklyReportMetrics['WASO [minutes]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0) === 0
      ? 1
      : weeklyReportMetrics['WASO [minutes]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0))
  const avgAbsence =
    weeklyReportMetrics['Absence [minutes]'].reduce((a, b) => a + b.value, 0) /
    (weeklyReportMetrics['Absence [minutes]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0) === 0
      ? 1
      : weeklyReportMetrics['Absence [minutes]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0))
  const avgCSI =
    weeklyReportMetrics['CSI [%]'].reduce((a, b) => a + b.value, 0) /
    (weeklyReportMetrics['CSI [%]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0) === 0
      ? 1
      : weeklyReportMetrics['CSI [%]'].reduce((a, b) => (b.value > 0 ? a + 1 : a), 0))

  weeklyReportMetrics['ROL [minutes]'].push({
    view: Math.round(avgROL),
    value: avgROL,
  })
  weeklyReportMetrics['Time in Bed [hours]'].push({
    view: convertMinsToHrsMins(avgTimeInBed),
    value: avgTimeInBed,
  })
  weeklyReportMetrics['TST [hours]'].push({
    view: convertMinsToHrsMins(avgTST),

    value: avgTST,
  })
  weeklyReportMetrics['SOL [minutes]'].push({
    view: Math.round(avgSOL),
    value: avgSOL,
  })
  weeklyReportMetrics['SE [%]'].push({
    view: Math.round(avgSE),
    value: avgSE,
  })
  weeklyReportMetrics['WASO [minutes]'].push({
    view: Math.round(avgWASO),
    value: avgWASO,
  })
  weeklyReportMetrics['TTA [minutes]'].push({
    view: Math.round(avgTTA),
    value: avgTTA,
  })
  weeklyReportMetrics['Absence [minutes]'].push({
    view: Math.round(avgAbsence),
    value: avgAbsence,
  })
  weeklyReportMetrics['CSI [%]'].push({
    view: Math.round(avgCSI),
    value: avgCSI,
  })

  const WakeAvg =
    (weeklyReportData.Wake.reduce((a, b) => a + b, 0) === 0
      ? 0
      : weeklyReportData.Wake.reduce((a, b) => a + b, 0)) /
    (weeklyReportData.Wake.reduce((a, b) => (b > 0 ? a + 1 : a), 0) === 0
      ? 1
      : weeklyReportData.Wake.reduce((a, b) => (b > 0 ? a + 1 : a), 0))
  const LightAvg =
    weeklyReportData.Light.reduce((a, b) => a + b, 0) /
    (weeklyReportData.Light.reduce((a, b) => (b > 0 ? a + 1 : a), 0) === 0
      ? 1
      : weeklyReportData.Light.reduce((a, b) => (b > 0 ? a + 1 : a), 0))
  const DeepAvg =
    weeklyReportData.Deep.reduce((a, b) => a + b, 0) /
    (weeklyReportData.Deep.reduce((a, b) => (b > 0 ? a + 1 : a), 0) === 0
      ? 1
      : weeklyReportData.Deep.reduce((a, b) => (b > 0 ? a + 1 : a), 0))
  const REMAvg =
    weeklyReportData.REM.reduce((a, b) => a + b, 0) /
    (weeklyReportData.REM.reduce((a, b) => (b > 0 ? a + 1 : a), 0) === 0
      ? 1
      : weeklyReportData.REM.reduce((a, b) => (b > 0 ? a + 1 : a), 0))
  weeklyReportData.Wake.push(Math.round(WakeAvg))
  weeklyReportData.REM.push(Math.round(REMAvg))
  weeklyReportData.Light.push(Math.round(LightAvg))
  weeklyReportData.Deep.push(Math.round(DeepAvg))

  const bodyClockObj = {
    userUID,
    graph: 'bodyclock_points',
  }
  const bodyClockImageObj = {
    userUID,
    graph: 'bodyclock',
  }
  const bodyClockProccessCObj = {
    userUID,
    graph: 'process_c',
  }
  const bodyClockPRCObj = {
    userUID,
    graph: 'prc',
  }
  const [
    memberBodyClockData,
    memberPRCImage,
    memberBodyClockImage,
    memberProcessCRequest,
  ] = yield all([
    call(getMemberBodyClock, bodyClockObj),
    call(getMemberBodyClock, bodyClockPRCObj),
    call(getMemberBodyClock, bodyClockImageObj),
    call(getMemberBodyClock, bodyClockProccessCObj),
  ])
  const memberProcessCImage = memberProcessCRequest.img ? memberProcessCRequest.img : ''
  const recommendation = memberProcessCRequest.recommendation
    ? memberProcessCRequest.recommendation
    : ''
  const bodyClockOffset = getOffsetFromRecommendation(recommendation)
  const CProcessData = yield call(formatCProcessData, memberBodyClockData)
  const timelineData = yield call(formatTimelineData, memberBodyClockData)
  const bodyclockSummary = yield call(formatBodyClockSummary, memberBodyClockData, bodyClockOffset)
  const formattedBodyClockData = { datasets: timelineData.concat(CProcessData) }

  let weeklyReportMetricsSummary = weeklyTrendsData.map((datum) => {
    datum.graphData = datum.graphData.reverse()
    datum.weekData = datum.weekData.reverse()
    if (datum.metricKey === 'time_awaken') {
      datum.metric = 'TTA [minutes]'
    }
    return datum
  })
  weeklyReportMetricsSummary = weeklyReportMetricsSummary.filter(
    (datum) =>
      datum.metricKey !== 'percent_light' &&
      datum.metricKey !== 'percent_deep' &&
      datum.metricKey !== 'percent_rem',
  )
  yield put({
    type: 'profile/SET_STATE',
    payload: {
      weeklyReportMetricsSummary,
      weeklyReportData,
      weeklyReportMetrics,
      reportSleepSessions,
      memberBodyClockData,
      bodyclockSummary,
      memberBodyClockImage,
      memberProcessCImage,
      deviceID,
      memberPRCImage,
      memberBodyClockGraphData: formattedBodyClockData,
      reportDataLoading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_MEMBER_DETAIL, GET_MEMBER_DETAIL),
    takeEvery(actions.GET_MEMBER_SLEEP_STAGES_HISTORY, GET_MEMBER_SLEEP_STAGES_HISTORY),
    takeEvery(actions.GET_MEMBER_BODY_CLOCK, GET_MEMBER_BODY_CLOCK),
    takeEvery(actions.GET_MEMBER_TRENDS, GET_MEMBER_TRENDS),
    takeEvery(actions.GET_WEEKLY_REPORT_DATA, GET_WEEKLY_REPORT_DATA),
  ])
}
