import { notification } from 'antd'
import { Auth } from 'aws-amplify'

import { firstTimeLogin } from '../redux/user/actions'

// function OnlyCircadiaUsers(email) {
//   const regex = /(circadiatech.com)/g // Insert whatever phrase or character you want to find
//   return regex.test(email) // => true
// }
export default async function login(username, password, payload) {
  /* *--- Uncomment to use custom SuperAdmin Auth role ---* if (username === 'superadmin@circadiatech.com' && password === 'devSleep101') {
    sessionStorage.setItem(
      'VC_session',
      JSON.stringify({
        Authorized: true,
        name: 'Amberin Fur',
        role: 'superAdmin',
        email: username,
      }),
    )
    return {
      success: true,
      type: 'Super Admin',
    }
  } *--- Uncomment to use custom SuperAdmin Auth role ---* */
  // const CircadiaUser = OnlyCircadiaUsers(username)
  // console.log('CircadiaUser', CircadiaUser)
  // if (CircadiaUser) {
  // console.log('payload', payload)
  try {
    return await Auth.signIn(username, password)
      .then(async user => {
        // console.log('authresponse login success', user)
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          if (payload.newPassword !== undefined) {
            // const { requiredAttributes } = res.challengeParam
            const { newPassword } = payload
            const email = username
            await Auth.completeNewPassword(
              user, // the Cognito User Object
              newPassword, // the new password
              // OPTIONAL, the required attributes
              {
                email,
              },
            )
            await firstTimeLogin()
            return {
              success: true,
              type: 'Coach',
            }
          }
          notification.warning({
            message: 'Change your password',
            description: 'New password required',
          })
          return {
            newPassReq: true,
            userDetails: {
              username,
              password,
            },
            error: 'New Password Required',
          }
        }
        return {
          success: true,
          type: 'Coach',
        }
      })
      .catch(e => {
        // console.log('authresponse login not success', e)
        notification.warning({
          message: 'Invalid Login',
          description: e.message,
        })
        return {
          success: false,
          error: 'invalid Username or Password',
        }
      })
    /* return await Auth.signIn(username, password)
      .then(res => {
        console.log('authresponse login success', res)
        // if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
        //   if (payload.newPassword !== undefined) {
        //     // const { requiredAttributes } = res.challengeParam
        //     const { newPassword, gender } = payload
        //     Auth.completeNewPassword(
        //       res, // the Cognito User Object
        //       newPassword, // the new password
        //       // OPTIONAL, the required attributes
        //       {
        //         gender,
        //       },
        //     )
        //     Auth.signIn(username, newPassword)
        //       .then(data => {
        //         console.log('authresponse login success', data)
        //         return {
        //           success: true,
        //           type: 'Coach',
        //         }
        //       })
        //       .catch(err => {
        //         console.log('authresponse login not success', err)
        //         notification.warning({
        //           message: 'Invalid Login',
        //           description: err.message,
        //         })
        //         return {
        //           success: false,
        //           error: 'invalid Username or Password',
        //         }
        //       })
        //   }
        //   notification.warning({
        //     message: 'Cannot procced with temp pass',
        //     description: 'New Password Required',
        //   })
        //   return {
        //     newPassReq: true,
        //     userDetails: {
        //       username,
        //       password,
        //     },
        //     error: 'New Password Required',
        //   }
        // }
        return {
          success: true,
          type: 'Coach',
        }
      })
      .catch(e => {
        console.log('authresponse login not success', e)
        notification.warning({
          message: 'Invalid Login',
          description: e.message,
        })
        return {
          success: false,
          error: 'invalid Username or Password',
        }
      }) */
  } catch (e) {
    console.log('authresponse login not success', e)
    notification.warning({
      message: 'Invalid Login',
      description: e.message,
    })
    return {
      success: false,
      error: 'invalid Username or Password',
    }
  }
  // }
  // notification.warning({
  //   message: 'Invalid Login',
  //   description: 'not allowed',
  // })
  // return {
  //   success: false,
  //   error: 'invalid Username or Password',
  // }
}
export async function currentAccount() {
  try {
    let firstName = ''
    let lastName = ''
    let userID = ''
    await Auth.currentSession().then(async () =>
      Auth.currentUserInfo().then(user => {
        firstName = user.attributes['custom:first_name']
        lastName = user.attributes['custom:last_name']
        userID = user.attributes.sub
      }),
    )
    return await Auth.currentSession().then(async ses =>
      Auth.currentUserCredentials().then(user => {
        const { accessKeyId, secretAccessKey, sessionToken } = user
        const { idToken } = ses
        const groups = idToken.payload['cognito:groups']
        return {
          name: 'Coach',
          role: 'Coach',
          groups,
          userID,
          fullName: `${firstName} ${lastName}`,
          firstName,
          lastName,
          accessKeyId,
          secretAccessKey,
          sessionToken,
          status: true,
        }
      }),
    )
  } catch (e) {
    // console.log('Error Loading current Account', e)
    return {
      status: false,
    }
  }
}
export async function logout() {
  await Auth.signOut().then(() => true)
}
