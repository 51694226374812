import { API } from 'aws-amplify'

const actions = {
  SET_STATE: 'member/SET_STATE',
  GET_MEMBERS: 'member/GET_MEMBERS',
}
export const getAllMembers = () =>
  API.get('virtualClinic', '/virtualclinic-temp/v1/allMembers')
    .then(res => res)
    .catch(err => {
      console.log('Error Loading AllMembers', err)
      return []
    })

export default actions
