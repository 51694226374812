import { Auth, API } from 'aws-amplify'

const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
  FORGETPASSWORD_SEND_CODE: 'user/FORGETPASSWORD_SEND_CODE',
  FORGOTPASSWORD_SUBMIT: 'user/FORGOTPASSWORD_SUBMIT',
}

export const forgotPasswordSendCode = username =>
  Auth.forgotPassword(username)
    .then(data => ({ status: 200, data }))
    .catch(err => ({ status: 403, err }))

export const forgotPasswordSubmit = (username, code, newPassword) =>
  Auth.forgotPasswordSubmit(username, code, newPassword)
    .then(data => ({ status: 200, data }))
    .catch(err => ({ status: 403, err }))

export const firstTimeLogin = () => {
  console.log('API Calling First Time Login ')
  return API.post('virtualClinic', `/user-agreement-dev/userAgreement`, {
    headers: {},
    body: {},
  })
    .then(res => res)
    .catch(err => {
      console.log('First Time Login', err)
      return []
    })
}

export default actions
